// path: web/src/utils/TokenChecker.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { jwtDecode } from "jwt-decode";
import LoadingScreen from "../components/LoadingScreen";

const TokenChecker = ({ children }) => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            localStorage.removeItem("token");
            setUser(null);
            navigate("/"); // Arahkan ke halaman login jika token kadaluarsa
          } else {
            setUser(decodedToken);
          }
        } catch (error) {
          window.logger.error("Error decoding token:", error);
          localStorage.removeItem("token");
          setUser(null);
          navigate("/"); // Arahkan ke halaman login jika terjadi error pada token
        }
      } else {
        setUser(null);
        navigate("/"); // Arahkan ke halaman login jika tidak ada token
      }
      setLoading(false);
    };

    checkTokenExpiration();
  }, [setUser, navigate]);

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};

export default TokenChecker;
