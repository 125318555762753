import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { verifyCode } from "../services/api";
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Box,
  Grid,
} from "@mui/material";

const VerifyCodePage = () => {
  const [code, setCode] = useState(Array(6).fill(""));
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  useEffect(() => {
    const email = localStorage.getItem("emailForVerification");
    if (!email) {
      navigate("/lupa-sandi");
    }
  }, [navigate]);

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("emailForVerification");
    const verificationCode = code.join("");
    try {
      const response = await verifyCode(email, verificationCode);

      setMessage(response.message);

      if (response.message === "Verification code is valid") {
        localStorage.setItem("email", email);
        localStorage.setItem("verificationCode", verificationCode);
        setSnackbarOpen(true);

        await new Promise((resolve) => {
          setTimeout(() => {
            setSnackbarOpen(false);
            resolve();
          }, 3000);
        });

        navigate("/reset-sandi");
      }
    } catch (error) {
      setMessage(
        "Error: " + (error.response?.data?.message || "Something went wrong.")
      );
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6) {
      const newCode = paste.split("").slice(0, 6);
      setCode(newCode);
      newCode.forEach((char, index) => {
        inputRefs.current[index].value = char;
      });
      inputRefs.current[5].focus();
    }
  };

  const handleChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value.slice(-1);
    setCode(newCode);

    if (e.target.value && index < 5) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ width: "100%", padding: 4, boxShadow: "none" }}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Verifikasi
          </Typography>
          <Typography variant="body1" gutterBottom>
            Masukkan kode verifikasi yang dikirimkan ke email Anda.
          </Typography>
          <Box sx={{ textAlign: "center", marginBottom: 2 }}>
            <img
              src="/assets/images/verifikasi-kode.svg"
              alt="Lupa Sandi"
              style={{ width: "100%", maxWidth: 300 }}
            />
          </Box>
          <form onSubmit={handleVerifyCode}>
            <Grid container spacing={1} justifyContent="center">
              {code.map((value, index) => (
                <Grid item key={index}>
                  <TextField
                    id={`code-input-${index}`}
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        fontSize: "1rem",
                        width: "1rem",
                      },
                    }}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    onPaste={(e) => handlePaste(e)}
                    required
                  />
                </Grid>
              ))}
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Verifikasi
            </Button>
          </form>
          {message && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {message}
            </Alert>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Kode verifikasi benar. Mengarahkan...
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VerifyCodePage;
