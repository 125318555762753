import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { register } from "../services/api";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";

const RegisterPage = () => {
  const [namaLengkap, setNamaLengkap] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [nomorHP, setNomorHP] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password tidak cocok.");
      return;
    }
    try {
      const userData = {
        username,
        email,
        password,
        nama_lengkap: namaLengkap,
        nomor_telepon: nomorHP,
      };
      await register(userData);
      setMessage("Registrasi berhasil. Silakan tunggu persetujuan admin.");
      navigate("/login");
    } catch (error) {
      setMessage("Registrasi gagal. Silakan coba lagi.");
    }
  };

  const handleNamaLengkapChange = (e) => {
    const fullName = e.target.value;
    setNamaLengkap(fullName);
    const lowerCaseUsername = fullName.toLowerCase().replace(/\s+/g, "");
    setUsername(lowerCaseUsername);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ width: "100%", padding: 4, boxShadow: "none" }}>
        <CardContent>
          <Box sx={{ textAlign: "left", marginBottom: 2 }}>
            <img
              src="/assets/icons/logo-ajeg-hijau.png"
              alt="Logo"
              style={{ width: 100, height: 100 }}
            />
          </Box>
          <Typography variant="h2" gutterBottom>
            Pendaftaran
          </Typography>
          <Typography variant="body1" gutterBottom>
            Selamat bergabung, Silahkan daftar untuk membuat akunmu.
          </Typography>
          <form onSubmit={handleRegister}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="namaLengkap"
              label="Nama Lengkap"
              value={namaLengkap}
              onChange={handleNamaLengkapChange}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="username"
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="nomorHP"
              label="Nomor HP"
              type="tel"
              value={nomorHP}
              onChange={(e) => setNomorHP(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? (
                        <FaEyeSlash style={{ fontSize: "1rem" }} />
                      ) : (
                        <FaEye style={{ fontSize: "1rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="confirmPassword"
              label="Konfirmasi Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowConfirmPassword} edge="end">
                      {showConfirmPassword ? (
                        <FaEyeSlash style={{ fontSize: "1rem" }} />
                      ) : (
                        <FaEye style={{ fontSize: "1rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Daftar
            </Button>
          </form>
          {message && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {message}
            </Alert>
          )}
          <Typography
            variant="body2"
            sx={{ marginTop: 2, textAlign: "center" }}
          >
            Sudah punya akun? <a href="/">Login</a>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegisterPage;
