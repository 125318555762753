// utils/dateUtils.jsx
export const formatDateIndo = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("id-ID", options);
};

export const formatTimeIndo = (timeString) => {
  const date = new Date(`1970-01-01T${timeString}`);
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  return date.toLocaleTimeString("id-ID", options);
};
