// path: pages/LoginPage.jsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { login } from "../services/api";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Box,
  Alert,
} from "@mui/material";

const LoginPage = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const { setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(identifier, password);
      window.logger.log("Data diterima dari API:", data); // Log data lengkap
      localStorage.setItem("token", data.token);
      setUser(data.user); // Simpan data pengguna di context
      window.logger.log("Login berhasil", data.user);

      // Arahkan kembali ke halaman yang diinginkan atau halaman home jika tidak ada
      const from = "/home";
      navigate(from);
    } catch (error) {
      if (error.response?.data?.message === "User not approved") {
        setMessage("Akun Anda belum disetujui oleh admin.");
      } else {
        setMessage("Login gagal. Silakan periksa kembali kredensial Anda.");
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      maxWidth="xs"
      sx={{ display: "flex", alignItems: "center", minHeight: "100vh" }}
    >
      <Card sx={{ width: "100%", padding: 4, boxShadow: "none" }}>
        <CardContent>
          <Box sx={{ textAlign: "left", mb: 2 }}>
            <img
              src="/assets/icons/logo-ajeg-hijau.png"
              alt="Logo"
              style={{ width: 100, height: 100 }}
            />
            <Typography variant="h2">Selamat Datang 👋</Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              di{" "}
              <span style={{ color: "#006d5a" }}>Absensi Ajeg Furniture</span>
            </Typography>
            <Typography variant="body1">
              Silahkan masuk untuk melanjutkan
            </Typography>
          </Box>
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="identifier"
              label="Email atau username"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ textAlign: "right", marginBottom: 2 }}>
              <Link href="/lupa-sandi">Lupa sandi kamu?</Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
            >
              Login
            </Button>
          </form>
          {message && <Alert severity="error">{message}</Alert>}
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Belum punya akun? <Link href="/daftar">Daftar</Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
