// path: components/PublicRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";

const PublicRoute = ({ element: Component, ...rest }) => {
  const { user } = useUser();
  const location = useLocation();

  return !user ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/home" state={{ from: location }} />
  );
};

export default PublicRoute;
