// path: web/src/pages/ManageLeaveRequests.jsx

import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { MdArrowBack } from "react-icons/md";
import {
  getAllLeaveRequests,
  approveLeaveRequest,
  rejectLeaveRequest,
} from "../services/api";
import { formatDateIndo } from "../utils/dateUtils";
import { VscCheck, VscClose } from "react-icons/vsc";
import theme from "../theme";

const ManageLeaveRequests = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [processedRequests, setProcessedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRowClick = (request) => {
    if (request.status === "Pending") {
      setSelectedRequest(request);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const requests = await getAllLeaveRequests();
        // Pisahkan berdasarkan status
        const pending = requests.filter((req) => req.status === "Pending");
        const processed = requests.filter(
          (req) => req.status === "Approved" || req.status === "Rejected"
        );
        setPendingRequests(pending);
        setProcessedRequests(processed);
      } catch (error) {
        window.logger.error("Error fetching leave requests:", error);
      }
    };

    fetchLeaveRequests();
  }, []);

  const handleApprove = async (id) => {
    try {
      await approveLeaveRequest(id);
      setPendingRequests((prev) => prev.filter((request) => request.id !== id));
      setProcessedRequests((prev) =>
        prev.map((request) =>
          request.id === id ? { ...request, status: "Approved" } : request
        )
      );
      handleCloseDialog();
    } catch (error) {
      window.logger.error("Error approving leave request:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      await rejectLeaveRequest(id);
      setPendingRequests((prev) => prev.filter((request) => request.id !== id));
      setProcessedRequests((prev) =>
        prev.map((request) =>
          request.id === id ? { ...request, status: "Rejected" } : request
        )
      );
      handleCloseDialog();
    } catch (error) {
      window.logger.error("Error rejecting leave request:", error);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Manajemen Cuti
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: 2,
          mb: 8,
        }}
      >
        <Box
          sx={{
            width: isMobile ? "320px" : "100%",
            overflowX: "auto",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "600" }}>
            Pengajuan Baru
          </Typography>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Karyawan</TableCell>
                  <TableCell>Mulai</TableCell>
                  <TableCell>Jenis</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingRequests.map((request) => (
                  <TableRow
                    key={request.id}
                    onClick={() => handleRowClick(request)}
                  >
                    <TableCell>{request.nama_lengkap}</TableCell>
                    <TableCell>{formatDateIndo(request.start_date)}</TableCell>
                    <TableCell>{request.leave_type}</TableCell>
                    <TableCell>{request.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ marginY: 4 }} />

          <Typography variant="subtitle2" sx={{ fontWeight: "600" }}>
            Pengajuan yang Sudah Diproses
          </Typography>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Karyawan</TableCell>
                  <TableCell>Mulai</TableCell>
                  <TableCell>Jenis</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {processedRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.nama_lengkap}</TableCell>
                    <TableCell>{formatDateIndo(request.start_date)}</TableCell>
                    <TableCell>{request.leave_type}</TableCell>
                    <TableCell>{request.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {selectedRequest && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          sx={{ maxWidth: "100%" }}
        >
          <DialogTitle sx={{ fontWeight: "600px", fontSize: "20px" }}>
            {selectedRequest.nama_lengkap} - {selectedRequest.leave_type}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body1">Mulai</Typography>
                <Typography variant="body1">Selesai</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  : {formatDateIndo(selectedRequest.start_date)}
                </Typography>
                <Typography variant="body1">
                  : {formatDateIndo(selectedRequest.end_date)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Alasan"
                  value={selectedRequest.reason}
                  variant="outlined"
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>

            {selectedRequest.proof_photo_url && (
              <img
                src={selectedRequest.proof_photo_url}
                alt="Proof"
                style={{ width: "200px" }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <IconButton
              onClick={() => handleApprove(selectedRequest.id)}
              color="primary"
            >
              <VscCheck />
              <Typography>Setujui</Typography>
            </IconButton>
            <IconButton
              onClick={() => handleReject(selectedRequest.id)}
              color="secondary"
            >
              <VscClose />
              <Typography>Tolak</Typography>
            </IconButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ManageLeaveRequests;
