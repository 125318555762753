// path: web/src/pages/AttendanceList.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { MdArrowBack } from "react-icons/md";
import { useUser } from "../context/UserContext";
import {
  getUserProfileById,
  getAttendanceHistory,
  getAvailableMonths,
} from "../services/api";
import UserProfileHeader from "../components/UserProfileHeader";
import theme from "../theme";
import { formatDateIndo, formatTimeIndo } from "../utils/dateUtils"; // Import utilitas

const AttendanceList = () => {
  const { user } = useUser();
  const [userProfile, setUserProfile] = useState(null);
  const [attendanceHistory, setAttendanceHistory] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfileById(user.id);
        setUserProfile(profile);
      } catch (error) {
        window.logger.error("Error fetching user profile:", error);
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const months = await getAvailableMonths(user.id);
        setAvailableMonths(months);
      } catch (error) {
        window.logger.error("Error fetching available months:", error);
      }
    };

    if (user) {
      fetchAvailableMonths();
    }
  }, [user]);

  useEffect(() => {
    const fetchAttendanceHistory = async () => {
      try {
        const history = await getAttendanceHistory(user.id, selectedMonth);
        setAttendanceHistory(history);
      } catch (error) {
        window.logger.error("Error fetching attendance history:", error);
      }
    };

    if (selectedMonth) {
      fetchAttendanceHistory();
    }
  }, [user, selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Buat objek untuk mengelompokkan entri absensi berdasarkan tanggal
  const groupedAttendance = attendanceHistory.reduce((acc, attendance) => {
    const date = attendance.date.split("T")[0]; // Dapatkan hanya bagian tanggal dari string ISO
    if (!acc[date]) {
      acc[date] = { checkIn: null, checkOut: null };
    }
    if (attendance.type === "check-in") {
      acc[date].checkIn = attendance;
    } else if (attendance.type === "check-out") {
      acc[date].checkOut = attendance;
    }
    return acc;
  }, {});

  // Dapatkan daftar tanggal dari objek yang dikelompokkan
  const dates = Object.keys(groupedAttendance);

  const calculateTotalHours = (checkInTime, checkOutTime) => {
    const checkIn = new Date(`1970-01-01T${checkInTime}Z`);
    const checkOut = new Date(`1970-01-01T${checkOutTime}Z`);
    const diffMs = checkOut - checkIn; // Selisih dalam milidetik
    const diffHrs = Math.floor(diffMs / 1000 / 60 / 60); // Selisih dalam jam
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / 1000 / 60); // Selisih dalam menit
    return `${diffHrs} jam ${diffMins} menit`;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Daftar Hadir
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 2,
          mb: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 2,
          }}
        >
          <UserProfileHeader userProfile={userProfile} />
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Bulan</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Bulan"
          >
            {availableMonths.map((month) => (
              <MenuItem key={month.month} value={month.month}>
                {new Date(month.month + "-01").toLocaleDateString("id-ID", {
                  month: "long",
                  year: "numeric",
                })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            width: isMobile ? "320px" : "100%",
            overflowX: "auto",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Tanggal</TableCell>
                  <TableCell>Masuk</TableCell>
                  <TableCell>Pulang</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dates.map((date) => (
                  <TableRow key={date}>
                    <TableCell>{formatDateIndo(date)}</TableCell>
                    <TableCell>
                      {groupedAttendance[date].checkIn ? (
                        <>
                          {formatTimeIndo(groupedAttendance[date].checkIn.time)}
                          <br />
                          {groupedAttendance[date].checkIn.location_label !==
                          "null"
                            ? groupedAttendance[date].checkIn.location_label
                            : "Diluar Kantor"}
                        </>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {groupedAttendance[date].checkOut ? (
                        <>
                          {formatTimeIndo(
                            groupedAttendance[date].checkOut.time
                          )}
                          <br />
                          {groupedAttendance[date].checkOut.location_label !==
                          "null"
                            ? groupedAttendance[date].checkOut.location_label
                            : "Diluar Kantor"}
                        </>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {groupedAttendance[date].checkIn &&
                      groupedAttendance[date].checkOut
                        ? calculateTotalHours(
                            groupedAttendance[date].checkIn.time,
                            groupedAttendance[date].checkOut.time
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendanceList;
