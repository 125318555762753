import { createTheme } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#006d5a",
    },
    secondary: {
      main: "#ff4081",
    },
    black: {
      main: "#1E1E1E",
    },
    yellow: {
      main: "#FFC831",
    },
    text: {
      primary: "#1E1E1E",
      secondary: "#666666",
    },
  },
  shadows: [
    "none",
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    ...Array(23).fill(
      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
    ),
  ],
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "2.5rem", // h1 lebih besar dari h2
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem", // h2 ukuran 2rem
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "1.5rem", // subtitle1 sedikit lebih kecil dari h2
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "1.25rem", // subtitle2 lebih kecil dari subtitle1
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem", // body1 ukuran dasar untuk teks
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem", // body2 lebih kecil dari body1
      fontWeight: 400,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          maxWidth: 500,
          width: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          height: 40,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
  },
});

export const GlobalCss = () => (
  <GlobalStyles
    styles={{
      html: {},
      body: {
        margin: 0,
        padding: 0,
        fontFamily: "Poppins, sans-serif",
        backgroundColor: "#5b5b5b",
      },
      "#root": {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    }}
  />
);

export default theme;
