// path: services/api.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Fungsi untuk login
export const login = async (identifier, password) => {
  const response = await api.post("/auth/login", { identifier, password });
  window.logger.log("Respon dari API login:", response.data); // Log respons dari API
  return response.data;
};

// Fungsi untuk register
export const register = async (userData) => {
  const response = await api.post("/auth/register", userData);
  return response.data;
};

// Fungsi untuk forgot password
export const forgotPassword = async (email) => {
  const response = await api.post("/auth/forgot-password", { email });
  return response.data;
};

// Fungsi untuk reset password
export const resetPassword = async (email, code, newPassword) => {
  const response = await api.post("/auth/reset-password", {
    email,
    code,
    newPassword,
  });
  return response.data;
};

// Fungsi untuk verifikasi kode
export const verifyCode = async (email, code) => {
  const response = await api.post("/auth/verify-code", { email, code });
  return response.data;
};

// Fungsi untuk mendapatkan data user
export const getUser = async () => {
  const response = await api.get("/user/profile");
  return response.data;
};

// Fungsi untuk mendapatkan semua profil pengguna
export const getAllUserProfiles = async () => {
  const response = await api.get("/users");
  return response.data;
};

// Fungsi untuk mendapatkan profil pengguna berdasarkan id_users
export const getUserProfileById = async (id_users) => {
  const response = await api.get(`/users/${id_users}`);
  return response.data;
};

// Fungsi untuk logout
export const logout = async (setUser) => {
  localStorage.removeItem("token");
  setUser(null);
};

// Fungsi untuk mendapatkan daftar pengguna yang belum disetujui
export const getUnapprovedUsers = async () => {
  const response = await api.get("/admin/unapproved-users");
  return response.data;
};

// Fungsi untuk menyetujui pengguna
export const approveUser = async (userId) => {
  const response = await api.post("/admin/approve-user", { userId });
  return response.data;
};

// Fungsi untuk menolak pengguna
export const rejectUser = async (userId) => {
  const response = await api.post("/admin/reject-user", { userId });
  return response.data;
};

// Fungsi untuk check-in
export const checkIn = async (
  userId,
  photo,
  locationLabel,
  note,
  isLate,
  utcDateTime
) => {
  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("photo", photo);
  formData.append("location_label", locationLabel);
  formData.append("note", note);
  formData.append("is_late", isLate);
  formData.append("datetime", utcDateTime);

  const response = await api.post("/attendance/check-in", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  window.logger.log("Response dari API checkIn:", response.data);
  return response.data;
};

// Fungsi untuk check-out
export const checkOut = async (userId, photo, locationLabel, note, isLate) => {
  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("photo", photo);
  formData.append("location_label", locationLabel);
  formData.append("note", note);
  formData.append("is_late", isLate);

  const response = await api.post("/attendance/check-out", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

// Fungsi untuk mendapatkan status kehadiran
export const getAttendanceStatus = async (userId) => {
  const response = await api.get(`/attendance/status/${userId}`);
  window.logger.log("Response dari API getAttendanceStatus:", response.data);

  return response.data;
};

// Fungsi untuk memperbarui profil pengguna
export const updateUserProfile = async (id_users, updates) => {
  const response = await api.put(`/users/${id_users}`, updates);
  return response.data;
};

// Fungsi untuk mendapatkan daftar divisi
export const getDivisions = async () => {
  const response = await api.get("/users/divisions");
  return response.data;
};

// Fungsi untuk mendapatkan daftar role berdasarkan divisi
export const getRolesByDivision = async (divisionId) => {
  const response = await api.get(`/users/roles?divisionId=${divisionId}`);
  return response.data;
};

// Fungsi untuk meng-upload foto profil
export const uploadProfilePicture = async (formData) => {
  const response = await api.post("/upload/profile", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

// Fungsi untuk mendapatkan daftar bulan yang tersedia untuk riwayat kehadiran
export const getAvailableMonths = async (userId) => {
  const response = await api.get(`/attendance/months/${userId}`);
  window.logger.log("Response dari API getAvailableMonths:", response.data);
  return response.data;
};

// Fungsi untuk mendapatkan riwayat kehadiran pengguna
export const getAttendanceHistory = async (userId, month) => {
  const response = await api.get(
    `/attendance/history?userId=${userId}&month=${month}`
  );
  window.logger.log("Response dari API getAttendanceHistory:", response.data);
  return response.data;
};

// Fungsi untuk mendapatkan ringkasan kehadiran bulanan
export const getMonthlySummary = async (userId) => {
  const response = await api.get(`/attendance/summary/${userId}`);
  return response.data;
};

// Fungsi untuk mengirim permintaan izin
export const submitLeaveRequest = async (leaveData) => {
  try {
    const response = await api.post("/leave", leaveData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 30000,
    });
    window.logger.log("Response dari API submitLeaveRequest:", response.data);
    return response.data;
  } catch (error) {
    window.logger.error("Error submitting leave request:", error);
    throw error;
  }
};

// Fungsi untuk mendapatkan daftar pengajuan cuti
export const getLeaveRequests = async (userId, month) => {
  const response = await api.get(
    `/leave/requests?userId=${userId}&month=${month}`
  );
  return response.data;
};

// Fungsi untuk mendapatkan semua pengajuan cuti
export const getAllLeaveRequests = async () => {
  const response = await api.get("/leave/requests/all");
  return response.data;
};

// Fungsi untuk menyetujui pengajuan cuti
export const approveLeaveRequest = async (id) => {
  const response = await api.post(`/leave/approve`, { id });
  return response.data;
};

// Fungsi untuk menolak pengajuan cuti
export const rejectLeaveRequest = async (id) => {
  const response = await api.post(`/leave/reject`, { id });
  return response.data;
};

// Fungsi untuk mendapatkan daftar karyawan
export const getEmployeeList = async () => {
  const response = await api.get("/users/employees");
  return response.data;
};

// Fungsi untuk mendapatkan daftar kehadiran karyawan
export const getAvailableMonthsForAllEmployees = async () => {
  const response = await api.get("/attendance/months");
  window.logger.log(
    "Response dari API getAvailableMonthsForAllEmployees:",
    response.data
  );
  return response.data;
};

// Fungsi untuk mendapatkan absensi semua karyawan berdasarkan bulan yang dipilih
export const getAttendanceForAllEmployees = async (selectedMonth) => {
  const response = await api.get(`/attendance/all`, {
    params: { month: selectedMonth },
  });
  window.logger.log(
    "Response dari API getAttendanceForAllEmployees:",
    response.data
  );
  return response.data;
};

export default api;
