// path: web/src/pages/ProfilePage.jsx
import React, { useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import { getUserProfileById, getMonthlySummary } from "../services/api";
import {
  Box,
  Avatar,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import ProfileMenu from "../components/ProfileMenu";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const [profile, setProfile] = useState(null);
  const [monthlySummary, setMonthlySummary] = useState({ hadir: 0, izin: 0 });
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const handleEditProfile = () => {
    navigate(`/edit-profile/${user.id}`);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userProfile = await getUserProfileById(user.id);
        setProfile(userProfile);
      } catch (error) {
        window.logger.error("Error fetching user profile:", error);
      }
    };

    const fetchMonthlySummary = async () => {
      try {
        const summary = await getMonthlySummary(user.id);
        const hadir =
          summary.find((item) => item.type === "check-in")?.total || 0;
        const izin = summary.find((item) => item.type === "izin")?.total || 0;
        setMonthlySummary({ hadir, izin });
      } catch (error) {
        window.logger.error("Error fetching monthly summary:", error);
      }
    };

    if (user) {
      fetchProfile();
      fetchMonthlySummary();
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: 2,
        width: "100%",
        overflowX: "auto", // Enable horizontal scroll
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          src={profile?.foto_profil}
          alt="Profile Picture"
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Typography variant="h6">{profile?.nama_lengkap}</Typography>
        <Typography variant="body2" color="textSecondary">
          {profile?.jabatan}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, borderRadius: 3 }}
          fullWidth
          onClick={handleEditProfile}
        >
          Edit Profil
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Card
              sx={{
                height: "100%",
                backgroundColor: "#EFF6EF",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {" "}
                <Typography variant="body2" color="textSecondary">
                  Total Hadir
                </Typography>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  {monthlySummary.hadir}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ height: "100%", backgroundColor: "#EFF6EF" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Total Izin
                </Typography>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  {monthlySummary.izin}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ProfileMenu
        handleLogout={handleLogout}
        userDivisiId={profile?.id_divisi}
      />
    </Box>
  );
};

export default ProfilePage;
