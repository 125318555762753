//path: src/pages/LeaveRequestPage.jsx

import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";
import { submitLeaveRequest } from "../services/api";
import { MdArrowBack } from "react-icons/md";
import { useUser } from "../context/UserContext";

const LeaveRequestPage = () => {
  const { user } = useUser();
  const [leaveType, setLeaveType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reason, setReason] = useState("");
  const [proofPhoto, setProofPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [proofPhotoName, setProofPhotoName] = useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    window.history.back();
  };

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 800;
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(resolve, "image/jpeg", 0.7);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedFile = await compressImage(file);
      setProofPhoto(compressedFile);
      setProofPhotoName(file.name);
    }
  };

  const handleCameraCapture = async (dataUri) => {
    const file = dataURItoBlob(dataUri);
    const compressedFile = await compressImage(file);
    setProofPhoto(compressedFile);
    setProofPhotoName("izin_proof.jpg");
  };

  // Fungsi untuk konversi data URI ke Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formatDate = (date) => {
      return date ? date.toLocaleDateString("en-CA") : "";
    };

    const leaveData = new FormData();
    leaveData.append("user_id", user.id);
    leaveData.append("leave_type", leaveType);
    leaveData.append("start_date", formatDate(startDate));
    leaveData.append("end_date", formatDate(endDate));
    leaveData.append("reason", reason);

    if (proofPhoto) {
      leaveData.append("proof_photo", proofPhoto, proofPhotoName);
    }

    try {
      await submitLeaveRequest(leaveData);
      setSnackbarMessage("Permintaan Izin submitted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      let errorMessage = "Failed to submit Permintaan Izin";
      if (error.response) {
        errorMessage = `Server error: ${error.response.status} ${
          error.response.data.message || ""
        }`;
        window.logger.error(
          "Server error:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        errorMessage =
          "No response from server. Please check your network connection.";
        window.logger.error("Network error: No response received");
      } else {
        errorMessage = error.message;
        window.logger.error("Error submitting leave request:", error.message);
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Permintaan Izin
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          minHeight: "100vh",
          padding: 2,
          mt: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Jenis Izin</InputLabel>
                <Select
                  label="Jenis Izin"
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                >
                  <MenuItem value="Sakit">Sakit</MenuItem>
                  <MenuItem value="Cuti">Cuti</MenuItem>
                  <MenuItem value="Alasan Lainnya">Alasan Lainnya</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Tanggal Mulai"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Kapan Masuk Lagi"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Jelaskan alasannya disini"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" component="label" fullWidth>
                Upload Bukti Izin
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              {proofPhotoName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Bukti terpilih: {proofPhotoName}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default LeaveRequestPage;
