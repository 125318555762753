import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPassword } from "../services/api";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password tidak cocok.");
      return;
    }
    try {
      const email = localStorage.getItem("email");
      const code = localStorage.getItem("verificationCode");
      const data = await resetPassword(email, code, password);
      setMessage(data.message);
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        navigate("/login");
      }, 3000);
    } catch (error) {
      setMessage(
        "Error: " + (error.response?.data?.message || "Something went wrong.")
      );
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ padding: 4, boxShadow: "none" }}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Reset Password
          </Typography>
          <Typography variant="body1" gutterBottom>
            Masukkan password baru Anda.
          </Typography>
          <Box sx={{ textAlign: "center", marginBottom: 2 }}>
            <img
              src="/assets/images/password-baru.svg"
              alt="Password Baru"
              style={{ width: "100%", maxWidth: 200 }}
            />
          </Box>
          <form onSubmit={handleResetPassword}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="password"
              label="Password Baru"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? (
                        <FaEyeSlash style={{ fontSize: "1rem" }} />
                      ) : (
                        <FaEye style={{ fontSize: "1rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="confirmPassword"
              label="Konfirmasi Password Baru"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowConfirmPassword} edge="end">
                      {showConfirmPassword ? (
                        <FaEyeSlash style={{ fontSize: "1rem" }} />
                      ) : (
                        <FaEye style={{ fontSize: "1rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Reset Password
            </Button>
          </form>
          {message && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {message}
            </Alert>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Password berhasil direset. Mengarahkan...
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResetPasswordPage;
