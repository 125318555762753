// path: web/src/pages/EmployeeList.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Tambahkan ini
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { MdArrowBack } from "react-icons/md";
import { getEmployeeList } from "../services/api";
import theme from "../theme";

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchEmployeeList = async () => {
      try {
        const data = await getEmployeeList();
        setEmployees(data);
      } catch (error) {
        window.logger.error("Error fetching employee list:", error);
      }
    };

    fetchEmployeeList();
  }, []);

  const handleRowClick = (userId) => {
    navigate(`/edit-profile/${userId}`); // Tambahkan ini
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Daftar Karyawan
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 2,
          mb: 8,
        }}
      >
        <Box
          sx={{
            width: isMobile ? "320px" : "100%",
            overflowX: "auto",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Divisi</TableCell>
                  <TableCell>Jabatan</TableCell>
                  <TableCell>Nomor Telepon</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee, index) => (
                  <TableRow
                    key={employee.id}
                    onClick={() => handleRowClick(employee.user_id)}
                    hover
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{employee.nama_lengkap}</TableCell>
                    <TableCell>{employee.divisi}</TableCell>
                    <TableCell>{employee.jabatan}</TableCell>
                    <TableCell>{employee.nomor_telepon}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeList;
