// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import VerifyCodePage from "./pages/VerifyCodePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import EditProfile from "./pages/EditProfile";
import AdminPage from "./pages/AdminPage";
import AttendanceList from "./pages/AttendanceList";
import AbsensiForm from "./pages/AbsensiForm";
import AbsensiPulangForm from "./pages/AbsensiPulangForm";
import LeaveRequestPage from "./pages/LeaveRequestPage";
import LeaveRequestsList from "./pages/LeaveRequestsList";
import ManageLeaveRequests from "./pages/ManageLeaveRequests";
import EmployeeAttendanceList from "./pages/EmployeeAttendanceList";
import EmployeeList from "./pages/EmployeeList";
import PrivateRoute from "./components/PrivateRoute";
import ManagementRoute from "./components/ManagementRoute";
import PublicRoute from "./components/PublicRoute";
import { Box } from "@mui/material";
import { UserProvider, useUser } from "./context/UserContext";
import TokenChecker from "./utils/TokenChecker";

function App() {
  const { setUser } = useUser();

  return (
    <UserProvider>
      <Router>
        <TokenChecker>
          <Box
            maxWidth="xs"
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
              backgroundColor: "white",
              width: "500px",
              pb: 5,
            }}
          >
            <Routes>
              {/* Rute Publik */}
              <Route path="/" element={<PublicRoute element={LoginPage} />} />
              <Route
                path="/daftar"
                element={<PublicRoute element={RegisterPage} />}
              />
              <Route
                path="/lupa-sandi"
                element={<PublicRoute element={ForgotPasswordPage} />}
              />
              <Route
                path="/verifikasi-kode"
                element={<PublicRoute element={VerifyCodePage} />}
              />
              <Route
                path="/reset-sandi"
                element={<PublicRoute element={ResetPasswordPage} />}
              />

              {/* Rute Privat */}
              <Route
                path="/home"
                element={<PrivateRoute element={HomePage} />}
              />
              <Route
                path="/profile"
                element={<PrivateRoute element={ProfilePage} />}
              />
              <Route
                path="/edit-profile/:id"
                element={<PrivateRoute element={EditProfile} />}
              />
              <Route
                path="/daftar-hadir"
                element={<PrivateRoute element={AttendanceList} />}
              />
              <Route
                path="/presensi"
                element={<PrivateRoute element={AbsensiForm} />}
              />
              <Route
                path="/presensi-pulang"
                element={<PrivateRoute element={AbsensiPulangForm} />}
              />
              <Route
                path="/izin"
                element={<PrivateRoute element={LeaveRequestPage} />}
              />
              <Route
                path="/pengajuan-izin"
                element={<PrivateRoute element={LeaveRequestsList} />}
              />

              {/* Rute Manajemen */}
              <Route
                path="/persetujuan-karyawan"
                element={<ManagementRoute element={AdminPage} />}
              />
              <Route
                path="/daftar-kehadiran"
                element={<ManagementRoute element={EmployeeAttendanceList} />}
              />
              <Route
                path="/manajemen-izin"
                element={<ManagementRoute element={ManageLeaveRequests} />}
              />
              <Route
                path="/daftar-karyawan"
                element={<ManagementRoute element={EmployeeList} />}
              />
            </Routes>
          </Box>
        </TokenChecker>
      </Router>
    </UserProvider>
  );
}

export default App;
