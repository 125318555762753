// path: web/src/components/UserProfileHeader.jsx
import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import theme from "../theme";

const UserProfileHeader = ({ userProfile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={userProfile?.foto_profil || null}
          alt="Profile"
          sx={{
            width: 56,
            height: 56,
            marginRight: 2,
            border: "2px solid",
            borderColor: theme.palette.primary.main,
          }}
        />
        <Box>
          <Typography variant="h6">
            {userProfile?.nama_lengkap || null}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {userProfile?.jabatan || "Karyawan"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfileHeader;
