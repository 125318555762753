// path: web/src/components/AbsensiForm.jsx
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext"; // Import useUser
import {
  TextField,
  Button,
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import Webcam from "react-webcam";
import {
  MdOutlinePhotoCamera,
  MdOutlineClose,
  MdOutlineArrowBackIos,
  MdRefresh,
} from "react-icons/md";
import { checkIn } from "../services/api";

const AbsensiForm = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook untuk navigasi
  const { isWithinRadius, currentAllowedLocation, attendanceStatus } =
    location.state;

  const getCurrentDateTime = () => {
    const now = new Date();

    // Format tanggal: YYYY-MM-DD
    const date = now.toISOString().split("T")[0];

    // Format waktu: HH:MM:SS
    const time = now.toTimeString().split(" ")[0];

    return { date, time };
  };

  const { user } = useUser(); // Dapatkan user dari context

  const [note, setNote] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // State untuk Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const webcamRef = React.useRef(null);

  useEffect(() => {
    if (attendanceStatus.checkInTime) {
      navigate("/home");
    }
  }, [attendanceStatus, navigate]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    window.logger.log("Captured imageSrc:", imageSrc); // Debug log
  }, [webcamRef]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userId = user.id; // Dapatkan user ID dari context
      const locationLabel = currentAllowedLocation
        ? currentAllowedLocation.label
        : null;

      if (!imageSrc) {
        setSnackbarMessage(
          "Tidak ada gambar yang ditangkap. Silakan ambil foto terlebih dahulu."
        );
        setOpenSnackbar(true);
        return; // Tambahkan return untuk menghentikan eksekusi jika tidak ada gambar
      }

      // Convert data URI ke file object
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };

      const photoBlob = dataURItoBlob(imageSrc);
      const photoFile = new File([photoBlob], "photo.jpg", {
        type: "image/jpeg",
      });

      // Dapatkan tanggal dan waktu saat ini
      const { date, time } = getCurrentDateTime();

      // Logika untuk menentukan isLate
      const workStartTime = "08:00"; // Waktu mulai kerja
      const isLate = time > workStartTime;

      // Gabungkan date dan time menjadi datetime dalam format ISO
      const combinedDateTime = `${date}T${time}`;
      window.logger.log("Combined Date Time:", combinedDateTime);

      // Konversi ke UTC
      const currentDateTime = new Date(combinedDateTime);
      if (isNaN(currentDateTime.getTime())) {
        throw new Error("Invalid time value");
      }
      const utcDateTime = currentDateTime.toISOString();
      window.logger.log("UTC Date Time:", utcDateTime);

      await checkIn(
        userId,
        photoFile,
        locationLabel,
        note,
        isLate,
        utcDateTime
      );

      setSnackbarMessage("Absen berhasil!");
      setOpenSnackbar(true);

      localStorage.setItem("hasCheckedIn", "true"); // Menyimpan status absensi

      setTimeout(() => {
        navigate("/home");
      }, 2000); // Navigasi setelah 1 detik
    } catch (error) {
      setSnackbarMessage("Terjadi kesalahan saat melakukan absensi.");
      setOpenSnackbar(true);
      window.logger.error(
        "Error saat melakukan absensi:",
        error.message || error
      );
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        height: "100%",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: 2 }}
            onClick={() => window.history.back()}
          >
            <MdOutlineArrowBackIos />
          </IconButton>

          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Form Kehadiran
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{
              borderRadius: "50%",
              border: "1px solid",
              color: "black",
            }}
            onClick={() => window.location.reload()}
          >
            <MdRefresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, mb: 5 }}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ my: 2 }}>
            {isWithinRadius && currentAllowedLocation ? (
              <>
                <Typography variant="body2" color="textSecondary">
                  Anda berada dalam radius pekerjaan di{" "}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {currentAllowedLocation.address}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="red">
                  Anda berada diluar radius pekerjaan!
                </Typography>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="note"
                  label="Jelaskan alasannya"
                  multiline
                  rows={4}
                  value={note}
                  onChange={handleNoteChange}
                  placeholder="Tambahkan catatan dan alasan"
                />
              </>
            )}
          </Box>

          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                alt="Captured"
                style={{ width: "50%", height: "50%", borderRadius: 12 }}
              />
              <Button
                onClick={() => setImageSrc(null)}
                variant="contained"
                color="yellow"
                fullWidth
                sx={{ mt: 2, color: "black" }}
                startIcon={<MdOutlineClose />}
              >
                Foto Ulang
              </Button>
            </>
          ) : (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="50%"
                style={{ borderRadius: 12 }}
              />
              <Button
                onClick={capture}
                variant="contained"
                fullWidth
                color="black"
                sx={{ mt: 2, color: "white" }}
                startIcon={<MdOutlinePhotoCamera />}
              >
                Ambil Foto
              </Button>
            </>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Absen Masuk
          </Button>
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AbsensiForm;
