// path: components/PrivateRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import BottomNav from "./BottomNav";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { user } = useUser();
  const location = useLocation();

  return user ? (
    <>
      <Component {...rest} />
      <BottomNav />
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
