// path: web/src/components/ProfileMenu.jsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  VscSignOut,
  VscCalendar,
  VscTable,
  VscCheckAll,
  VscCodeOss,
  VscChevronRight,
  VscChecklist,
} from "react-icons/vsc";

const ProfileMenu = ({ handleLogout, userDivisiId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const MANAGER_DIVISI_ID = 1; // Misal ID untuk manajemen adalah 1

  const menuGeneral = [
    {
      icon: <VscCalendar />,
      text: "Daftar Hadir",
      action: () => navigate("/daftar-hadir"),
    },
    {
      icon: <VscCalendar />,
      text: "Pengajuan Izin",
      action: () => navigate("/pengajuan-izin"),
    },
  ];

  const menuAdmin = [
    {
      icon: <VscCheckAll />,
      text: "Persetujuan Karyawan",
      action: () => navigate("/persetujuan-karyawan"),
    },
    {
      icon: <VscChecklist />,
      text: "Pengajuan Izin",
      action: () => navigate("/manajemen-izin"),
    },
    {
      icon: <VscTable />,
      text: "Absensi Karyawan",
      action: () => navigate("/daftar-kehadiran"),
    },

    {
      icon: <VscCodeOss />,
      text: "Data Karyawan",
      action: () => navigate("/daftar-karyawan"),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderMenuItems = (menuItems) => (
    <List sx={{ width: "100%" }}>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            onClick={item.action}
            sx={{
              padding: "16px 24px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#F7F7F8",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "inherit", fontWeight: 500 }}>
                  {item.text}
                </Typography>
              }
            />
            {!item.noArrow && (
              <Box sx={{ marginLeft: "auto" }}>
                <VscChevronRight />
              </Box>
            )}
          </ListItem>
          {index < menuItems.length - 1 && (
            <Divider sx={{ borderColor: "#F7F7F8" }} />
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Menu Umum" />
        {userDivisiId === MANAGER_DIVISI_ID && <Tab label="Menu Admin" />}
      </Tabs>
      <Box>
        {tabIndex === 0 && renderMenuItems(menuGeneral)}
        {tabIndex === 1 &&
          userDivisiId === MANAGER_DIVISI_ID &&
          renderMenuItems(menuAdmin)}
      </Box>
      <List sx={{ width: "100%", mt: 1 }}>
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            padding: "16px 24px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#ffdddd",
            },
          }}
        >
          {" "}
          <ListItemIcon sx={{ color: "#ff4444" }}>
            <VscSignOut />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{ color: "#ff4444", fontWeight: 500 }}>
                Keluar
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default ProfileMenu;
