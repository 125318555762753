import { add } from "date-fns";

// src/allowedLocations.jsx
const allowedLocations = [
  /*
  {
    latitude: -7.7897877,
    longitude: 110.3988463,
    radius: 500,
    address: "Joglo, Indonesia",
    label: "Joglo Office",
  },
  */
  {
    latitude: -7.7822066,
    longitude: 110.4122813,
    radius: 200,
    address: "PT Furnindo Inovasi Teknologi.",
    label: "Furnindo Office",
  },
  {
    latitude: -7.7829405,
    longitude: 110.4413732,
    radius: 200,
    address: "Jl. Raya Solo - Yogyakarta",
    label: "Showroom",
  },
  {
    latitude: -7.7802389,
    longitude: 110.4076526,
    radius: 200,
    address: "Ngentak, Caturtunggal, Depok.",
    label: "Janti Office",
  },
  {
    latitude: -7.7826237,
    longitude: 110.4381391,
    radius: 200,
    address: "Sorogenen, Purwomartani, Kalasan.",
    label: "Gudang Sorogenen",
  },
  {
    latitude: -7.7192825,
    longitude: 110.5697968,
    radius: 200,
    address: "Dusun III, Gondang, Kebonarum, Klaten.",
    label: "Produksi Gondang",
  },
  /*

  {
    latitude: -6.17511,
    longitude: 106.865039,
    radius: 500,
    address: "Monas, Jakarta",
    label: "Monas Office",
  },
  */
  // Tambahkan lokasi lain di sini
];

export default allowedLocations;
