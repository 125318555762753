// path: web/src/pages/AdminPage.jsx
import React, { useEffect, useState } from "react";
import {
  getUnapprovedUsers,
  approveUser,
  rejectUser,
  updateUserProfile,
  getDivisions,
  getRolesByDivision,
} from "../services/api";
import {
  AppBar,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
} from "@mui/material";
import { MdArrowBack } from "react-icons/md";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [roles, setRoles] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchUnapprovedUsers = async () => {
      const data = await getUnapprovedUsers();
      setUsers(data);
    };

    const fetchDivisions = async () => {
      const divisions = await getDivisions();
      setDivisions(divisions);
    };

    fetchUnapprovedUsers();
    fetchDivisions();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      if (selectedUser?.id_divisi) {
        const roles = await getRolesByDivision(selectedUser.id_divisi);
        setRoles(roles);
      } else {
        setRoles([]);
      }
    };

    fetchRoles();
  }, [selectedUser?.id_divisi]);

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleApprove = async () => {
    try {
      await updateUserProfile(selectedUser.id, selectedUser);
      await approveUser(selectedUser.id);
      setUsers(users.filter((user) => user.id !== selectedUser.id));
      setDialogOpen(false);
      showSnackbar("User berhasil disetujui.", "success");
    } catch (error) {
      showSnackbar("Gagal menyetujui user.", "error");
    }
  };

  const handleReject = async () => {
    try {
      await rejectUser(selectedUser.id);
      setUsers(users.filter((user) => user.id !== selectedUser.id));
      setDialogOpen(false);
      showSnackbar("User berhasil ditolak.", "success");
    } catch (error) {
      showSnackbar("Gagal menolak user.", "error");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton
            onClick={() => window.history.back()}
            sx={{ mr: 1, color: "white" }}
          >
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Admin Page
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 2, minHeight: "100vh", mb: 5 }}>
        <Typography variant="subtitle2" gutterBottom>
          Menunggu persetujuan
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nama Lengkap</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  onClick={() => handleRowClick(user)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{user.nama_lengkap}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {selectedUser && (
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Detail Pengguna</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nama Lengkap"
                    name="nama_lengkap"
                    value={selectedUser.nama_lengkap}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Divisi</InputLabel>
                    <Select
                      label="Divisi"
                      name="id_divisi"
                      value={selectedUser.id_divisi}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {divisions.map((division) => (
                        <MenuItem key={division.id} value={division.id}>
                          {division.nama_divisi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Role</InputLabel>
                    <Select
                      label="Role"
                      name="id_role"
                      value={selectedUser.id_role}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.nama_role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nomor HP"
                    name="nomor_telepon"
                    value={selectedUser.nomor_telepon}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Alamat"
                    name="alamat"
                    value={selectedUser.alamat}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReject} color="secondary">
                Tolak
              </Button>
              <Button onClick={handleApprove} color="primary">
                Setujui
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default AdminPage;
