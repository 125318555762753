import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { FaHome, FaUser, FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BottomNav = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/Izin");
        break;
      case 2:
        navigate("/profile");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        maxWidth: "500px",
        margin: "auto",
      }}
      elevation={3}
    >
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction label="Home" icon={<FaHome />} />

        <BottomNavigationAction label="Izin / Cuti" icon={<FaEnvelope />} />
        <BottomNavigationAction label="Profile" icon={<FaUser />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
