// path: components/ManagementRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import BottomNav from "./BottomNav";

const ManagementRoute = ({ element: Component, ...rest }) => {
  const { user } = useUser();
  const location = useLocation();

  // Pastikan user sudah ada dan id_divisi bukan null atau undefined
  if (user) {
    const isManagement = user.id_divisi === 1;

    if (isManagement) {
      return (
        <>
          <Component {...rest} />
          <BottomNav />
        </>
      );
    }
  }

  // Jika user tidak ada atau bukan manajemen, arahkan ke halaman login
  return <Navigate to="/" state={{ from: location }} />;
};

export default ManagementRoute;
