import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../services/api";
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(email);
      setMessage(response.message);
      localStorage.setItem("emailForVerification", email);
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate("/verifikasi-kode");
      }, 3000);
    } catch (error) {
      setMessage(
        "Error: " + (error.response?.data?.message || "Something went wrong.")
      );
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", alignItems: "center", minHeight: "100vh" }}
    >
      <Card sx={{ width: "100%", padding: 4, boxShadow: "none" }}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Lupa Sandi
          </Typography>
          <Typography variant="body1" gutterBottom>
            Masukan alamat email terdaftar, jika email benar, kode akan
            dikirimkan ke email
          </Typography>
          <Box sx={{ textAlign: "center", marginBottom: 2 }}>
            <img
              src="/assets/images/lupa-sandi.svg"
              alt="Lupa Sandi"
              style={{ width: "100%", maxWidth: 200 }}
            />
          </Box>
          <form onSubmit={handleForgotPassword}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Kirim kode verifikasi
            </Button>
          </form>
          {message && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {message}
            </Alert>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Cek email Anda untuk kode verifikasi.
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPasswordPage;
