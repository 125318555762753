// path: web/src/pages/LeaveRequestsList.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaBell } from "react-icons/fa";
import { MdArrowBack } from "react-icons/md";
import { useUser } from "../context/UserContext";
import {
  getUserProfileById,
  getLeaveRequests,
  getAvailableMonths,
} from "../services/api";
import theme from "../theme";
import { formatDateIndo } from "../utils/dateUtils"; // Import utilitas
import UserProfileHeader from "../components/UserProfileHeader"; // Import komponen

const LeaveRequestsList = () => {
  const { user } = useUser();
  const [userProfile, setUserProfile] = useState(null);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfileById(user.id);
        setUserProfile(profile);
      } catch (error) {
        window.logger.error("Error fetching user profile:", error);
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const months = await getAvailableMonths(user.id);
        setAvailableMonths(months);
      } catch (error) {
        window.logger.error("Error fetching available months:", error);
      }
    };

    if (user) {
      fetchAvailableMonths();
    }
  }, [user]);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const requests = await getLeaveRequests(user.id, selectedMonth);
        setLeaveRequests(requests);
      } catch (error) {
        window.logger.error("Error fetching leave requests:", error);
      }
    };

    if (selectedMonth) {
      fetchLeaveRequests();
    }
  }, [user, selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Daftar Pengajuan Cuti
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 2,
          mb: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 2,
          }}
        >
          <UserProfileHeader userProfile={userProfile} />
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Bulan</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Bulan"
          >
            {availableMonths.map((month) => (
              <MenuItem key={month.month} value={month.month}>
                {new Date(month.month + "-01").toLocaleDateString("id-ID", {
                  month: "long",
                  year: "numeric",
                })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            width: isMobile ? "320px" : "100%",
            overflowX: "auto",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Tanggal Mulai</TableCell>
                  <TableCell>Tanggal Selesai</TableCell>
                  <TableCell>Alasan</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{formatDateIndo(request.start_date)}</TableCell>
                    <TableCell>{formatDateIndo(request.end_date)}</TableCell>
                    <TableCell>{request.reason}</TableCell>
                    <TableCell>{request.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default LeaveRequestsList;
