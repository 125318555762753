import * as Sentry from "@sentry/react";

const logger = {
  log: (...args) => {
    const message = args
      .map((arg) => (typeof arg === "object" ? JSON.stringify(arg) : arg))
      .join(" ");
    if (process.env.REACT_APP_NODE_ENV === "production") {
      Sentry.captureMessage(`LOG: ${message}`);
    } else {
      console.log(...args);
    }
  },
  warn: (...args) => {
    const message = args
      .map((arg) => (typeof arg === "object" ? JSON.stringify(arg) : arg))
      .join(" ");
    if (process.env.REACT_APP_NODE_ENV === "production") {
      Sentry.captureMessage(`WARNING: ${message}`, "warning");
    } else {
      console.warn(...args);
    }
  },
  error: (...args) => {
    const message = args
      .map((arg) => (typeof arg === "object" ? JSON.stringify(arg) : arg))
      .join(" ");
    const errorMessage = `ERROR: ${message}`;
    if (process.env.REACT_APP_NODE_ENV === "production") {
      Sentry.captureException(new Error(errorMessage));
    } else {
      window.logger.error(...args);
    }
  },
};

// Tambahkan logger ke objek global
window.logger = logger;

export default logger;
