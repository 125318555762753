// path: web/src/pages/EditProfile.jsx
import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../context/UserContext";
import {
  getUserProfileById,
  updateUserProfile,
  getDivisions,
  getRolesByDivision,
  uploadProfilePicture,
} from "../services/api";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  AppBar,
  Toolbar,
  Slider,
  Snackbar,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { useParams, useNavigate } from "react-router-dom";
import { MdCameraAlt, MdArrowBack } from "react-icons/md";

const EditProfile = () => {
  const { user } = useUser();
  const [profile, setProfile] = useState({
    nama_lengkap: "",
    id_divisi: "",
    id_role: "",
    nomor_telepon: "",
    alamat: "",
    foto_profil: "",
  });
  const [divisions, setDivisions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const editorRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [scale, setScale] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [dialogOpen, setDialogOpen] = useState(false);

  const isFieldDisabled = user?.id_divisi !== 1;

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userProfile = await getUserProfileById(id);
        setProfile(userProfile);
      } catch (error) {
        window.logger.error("Error fetching user profile:", error);
      }
    };

    const fetchDivisions = async () => {
      try {
        const divisions = await getDivisions();
        setDivisions(divisions);
      } catch (error) {
        window.logger.error("Error fetching divisions:", error);
      }
    };

    fetchProfile();
    fetchDivisions();
  }, [id]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (profile.id_divisi) {
        try {
          const roles = await getRolesByDivision(profile.id_divisi);
          setRoles(roles);
        } catch (error) {
          window.logger.error("Error fetching roles:", error);
        }
      } else {
        try {
          const roles = await getRolesByDivision(null);
          setRoles(roles);
        } catch (error) {
          window.logger.error("Error fetching roles:", error);
        }
      }
    };

    fetchRoles();
  }, [profile.id_divisi]);

  const handleBack = () => {
    window.history.back();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setEditorOpen(true);
  };

  const handleCrop = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      setCroppedImage(canvas);
      setEditorOpen(false);
    }
  };

  const handleSave = async () => {
    let fotoProfilUrl = profile.foto_profil;

    if (croppedImage) {
      const blob = await (await fetch(croppedImage)).blob();
      const formData = new FormData();
      formData.append("photo", blob, "profile.png");
      formData.append("userId", id);

      try {
        const response = await uploadProfilePicture(formData);
        fotoProfilUrl = response.url;
      } catch (error) {
        window.logger.error("Error uploading profile picture:", error);
        showSnackbar("Gagal mengunggah foto profil.", "error");
        return;
      }
    }

    try {
      const updatedProfile = {
        ...profile,
        id_divisi: profile.id_divisi || null,
        id_role: profile.id_role || null,
        foto_profil: fotoProfilUrl,
      };
      await updateUserProfile(id, updatedProfile);
      setDialogOpen(true); // Tampilkan dialog saat berhasil disimpan
    } catch (error) {
      window.logger.error("Error updating profile:", error);
      showSnackbar("Gagal memperbarui profil.", "error");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Edit Profile
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          minHeight: "100vh",
          padding: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            cursor: "pointer", // Tambahkan ini
          }}
          onClick={() => document.getElementById("fileInput").click()} // Tambahkan ini
        >
          <Avatar
            src={croppedImage || profile.foto_profil}
            alt="Profile Picture"
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: "calc(50% - 50px)",
              backgroundColor: "white",
            }} // Tambahkan ini
          >
            <MdCameraAlt />
          </IconButton>
          <input
            id="fileInput"
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Box>

        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nama Lengkap"
              name="nama_lengkap"
              value={profile.nama_lengkap}
              onChange={handleChange}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Divisi</InputLabel>
                  <Select
                    label="Divisi"
                    name="id_divisi"
                    value={profile.id_divisi || ""}
                    onChange={handleChange}
                    disabled={isFieldDisabled}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {divisions.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                        {division.nama_divisi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="id_role"
                    label="Role"
                    value={profile.id_role || ""}
                    onChange={handleChange}
                    disabled={isFieldDisabled}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.nama_role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nomor Telepon"
              name="nomor_telepon"
              value={profile.nomor_telepon}
              onChange={handleChange}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alamat"
              name="alamat"
              value={profile.alamat}
              onChange={handleChange}
              disabled={isFieldDisabled}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, borderRadius: 3 }}
          fullWidth
          onClick={handleSave}
        >
          Simpan
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <Button color="inherit" onClick={() => setSnackbarOpen(false)}>
            Tutup
          </Button>
        }
        ContentProps={{
          sx: {
            backgroundColor: snackbarSeverity === "success" ? "green" : "red",
            color: "white",
          },
        }}
      />

      {/* Dialog untuk konfirmasi penyimpanan */}
      <Dialog open={dialogOpen}>
        <DialogTitle>Perubahan Berhasil Disimpan</DialogTitle>
        <DialogContent>
          <Typography>
            Perubahan pada profil telah berhasil disimpan.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack}>Kembali</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog untuk Avatar Editor */}
      <Dialog open={editorOpen} onClose={() => setEditorOpen(false)}>
        <DialogTitle>Crop Foto Profil</DialogTitle>
        <DialogContent>
          {selectedFile && (
            <AvatarEditor
              ref={editorRef}
              image={selectedFile}
              width={200}
              height={200}
              border={10}
              borderRadius={125} // membuat crop menjadi persegi
              scale={scale} // Gunakan nilai scale dari state
            />
          )}
          <Slider
            value={scale}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setScale(value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditorOpen(false)}>Batal</Button>
          <Button onClick={handleCrop}>Simpan</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditProfile;
