// path: web/src/pages/EmployeeAttendanceList.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Button, // Tambahkan ini
  useMediaQuery,
} from "@mui/material";
import { MdArrowBack } from "react-icons/md";
import {
  getAttendanceForAllEmployees,
  getAvailableMonthsForAllEmployees,
} from "../services/api";
import Papa from "papaparse"; // Import Papaparse untuk CSV export
import theme from "../theme";
import { formatDateIndo, formatTimeIndo } from "../utils/dateUtils";

const EmployeeAttendanceList = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const months = await getAvailableMonthsForAllEmployees();
        setAvailableMonths(months);
      } catch (error) {
        window.logger.error("Error fetching available months:", error);
      }
    };

    fetchAvailableMonths();
  }, []);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      if (selectedMonth) {
        try {
          const { data, uniqueDates } = await getAttendanceForAllEmployees(
            selectedMonth
          );

          uniqueDates.sort((a, b) => new Date(a) - new Date(b));
          setAttendanceData(data);
          setDates(uniqueDates);
        } catch (error) {
          window.logger.error("Error fetching attendance data:", error);
        }
      }
    };

    fetchAttendanceData();
  }, [selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const calculateTotalHours = (checkInTime, checkOutTime) => {
    const checkIn = new Date(`1970-01-01T${checkInTime}Z`);
    const checkOut = new Date(`1970-01-01T${checkOutTime}Z`);
    const diffMs = checkOut - checkIn; // Selisih dalam milidetik
    const diffHrs = Math.floor(diffMs / 1000 / 60 / 60); // Selisih dalam jam
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / 1000 / 60); // Selisih dalam menit
    return `${diffHrs} jam ${diffMins} menit`;
  };

  const handleExportCSV = () => {
    const formattedData = attendanceData.map((employee) => {
      const row = { Name: employee.name };
      dates.forEach((date) => {
        if (employee.attendance[date]?.leave) {
          row[`Masuk ${date}`] = employee.attendance[date].leave.leave_type;
          row[`Lokasi Masuk ${date}`] = "Cuti/Izin";
          row[`Pulang ${date}`] = employee.attendance[date].leave.leave_type;
          row[`Lokasi Pulang ${date}`] = "Cuti/Izin";
          row[`Total Jam ${date}`] = "Cuti/Izin";
        } else {
          row[`Masuk ${date}`] =
            employee.attendance[date]?.checkIn?.time || "Tidak Masuk";
          row[`Lokasi Masuk ${date}`] =
            employee.attendance[date]?.checkIn?.location_label || "Di Luar";
          row[`Pulang ${date}`] =
            employee.attendance[date]?.checkOut?.time || "Tidak Pulang";
          row[`Lokasi Pulang ${date}`] =
            employee.attendance[date]?.checkOut?.location_label || "Di Luar";
          row[`Total Jam ${date}`] =
            employee.attendance[date]?.checkIn &&
            employee.attendance[date]?.checkOut
              ? calculateTotalHours(
                  employee.attendance[date].checkIn.time,
                  employee.attendance[date].checkOut.time
                )
              : "-";
        }
      });
      return row;
    });

    const csv = Papa.unparse(formattedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `attendance_${selectedMonth}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            <MdArrowBack />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              height: "48px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            Daftar Absensi Karyawan
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 2,
          mb: 8,
        }}
      >
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Bulan</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Bulan"
          >
            {availableMonths.map((month) => (
              <MenuItem key={month.month} value={month.month}>
                {new Date(month.month + "-01").toLocaleDateString("id-ID", {
                  month: "long",
                  year: "numeric",
                })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Tombol Export muncul setelah bulan dipilih */}
        {selectedMonth && (
          <Button
            variant="contained"
            color="primary"
            sx={{ marginBottom: 2 }}
            onClick={handleExportCSV}
          >
            Export ke CSV
          </Button>
        )}

        <Box
          sx={{
            width: isMobile ? "320px" : "100%",
            overflowX: "auto",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  {dates.map((date) => (
                    <TableCell key={date} colSpan={3} align="center">
                      {formatDateIndo(date)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {dates.map((date) => (
                    <React.Fragment key={date}>
                      <TableCell key={`${date}-masuk`}>Masuk</TableCell>
                      <TableCell key={`${date}-pulang`}>Pulang</TableCell>
                      <TableCell key={`${date}-total`}>Total</TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceData.map((employee, index) => (
                  <TableRow key={employee.user_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{employee.name}</TableCell>
                    {dates.map((date) => (
                      <React.Fragment key={`${date}-${employee.user_id}`}>
                        <TableCell key={`${date}-${employee.user_id}-masuk`}>
                          {employee.attendance[date]?.leave ? (
                            employee.attendance[date].leave.leave_type
                          ) : employee.attendance[date]?.checkIn ? (
                            <>
                              {formatTimeIndo(
                                employee.attendance[date].checkIn.time
                              )}
                              <br />
                              <small>
                                {employee.attendance[date].checkIn
                                  .location_label || "Di Luar"}
                              </small>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell key={`${date}-${employee.user_id}-pulang`}>
                          {employee.attendance[date]?.leave ? (
                            employee.attendance[date].leave.leave_type
                          ) : employee.attendance[date]?.checkOut ? (
                            <>
                              {formatTimeIndo(
                                employee.attendance[date].checkOut.time
                              )}
                              <br />
                              <small>
                                {employee.attendance[date].checkOut
                                  .location_label || "Di Luar"}
                              </small>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell key={`${date}-${employee.user_id}-total`}>
                          {employee.attendance[date]?.leave
                            ? "Cuti/Izin"
                            : employee.attendance[date]?.checkIn &&
                              employee.attendance[date]?.checkOut
                            ? calculateTotalHours(
                                employee.attendance[date].checkIn.time,
                                employee.attendance[date].checkOut.time
                              )
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeAttendanceList;
