// src/components/LoadingScreen.js
import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <img
        src="/assets/icons/logo-ajeg-hijau.png"
        alt="Logo"
        style={{
          width: "150px",
          height: "150px",
          position: "absolute",
        }}
      />
      <CircularProgress
        size={200}
        sx={{
          color: "green", // Sesuaikan warna jika perlu
        }}
      />
    </Box>
  );
};

export default LoadingScreen;
