// path: web/src/pages/HomePage.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import {
  Box,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import allowedLocations from "../components/allowedLocations";
import { MdRefresh } from "react-icons/md";
import { useUser } from "../context/UserContext";
import { getAttendanceStatus, getUserProfileById } from "../services/api"; // Import fungsi API
import UserProfileHeader from "../components/UserProfileHeader";

const HomePage = () => {
  const { user } = useUser(); // Ambil user dan setUser dari context
  const [userProfile, setUserProfile] = useState(null); // State untuk profil pengguna
  const navigate = useNavigate(); // Inisialisasi useNavigate

  const handleNavigateToPresensi = (type) => {
    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const currentDate = new Date().toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    navigate(type === "check-in" ? "/presensi" : "/presensi-pulang", {
      state: {
        isWithinRadius,
        currentAllowedLocation,
        currentTime,
        currentDate,
        attendanceStatus, // Tambahkan status kehadiran di sini
        type, // Tambahkan type untuk menentukan absen masuk atau absen pulang
      },
    });
  };

  const days = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];

  const getWeekDates = () => {
    const today = new Date();
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - today.getDay() + i);
      const day = date.getDate().toString().padStart(2, "0");
      const dayName = days[date.getDay()];
      weekDates.push({ day, dayName });
    }
    return weekDates;
  };

  const weekDates = getWeekDates();

  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState({
    checkInTime: null,
    checkOutTime: null,
    isLate: false,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeString = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      setCurrentTime(timeString);

      const dateOptions = {
        day: "2-digit",
        month: "long", // bisa diganti dengan "short" atau "numeric"
        year: "numeric",
      };
      setCurrentDate(now.toLocaleDateString("id-ID", dateOptions)); // sesuaikan dengan locale yang diinginkan
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfileById(user.id);
        setUserProfile(profile);
      } catch (error) {
        window.logger.error("Error saat mengambil profil pengguna:", error);
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const [isWithinRadius, setIsWithinRadius] = useState(false);
  const [currentAllowedLocation, setCurrentAllowedLocation] = useState(null);
  const [locationChecked, setLocationChecked] = useState(false);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Radius bumi dalam meter
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ dalam radian
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // dalam meter
    return distance;
  };

  const checkLocation = (currentLocation) => {
    for (let allowedLocation of allowedLocations) {
      const distance = calculateDistance(
        currentLocation.latitude,
        currentLocation.longitude,
        allowedLocation.latitude,
        allowedLocation.longitude
      );
      if (distance <= allowedLocation.radius) {
        setIsWithinRadius(true);
        setCurrentAllowedLocation(allowedLocation);
        return;
      }
    }
    setIsWithinRadius(false);
    setCurrentAllowedLocation(null);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          window.logger.log("Lokasi berhasil didapatkan:", currentLocation); // Log untuk lokasi berhasil
          checkLocation(currentLocation);
          setLocationChecked(true); // Set status lokasi sudah diperiksa
        },
        (error) => {
          window.logger.error("Error mendapatkan lokasi:", error); // Log untuk error lokasi
          setLocationChecked(true); // Set status lokasi sudah diperiksa meskipun terjadi error
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      window.logger.log("Geolocation tidak didukung oleh browser ini."); // Log untuk geolocation tidak didukung
      setLocationChecked(true); // Set status lokasi sudah diperiksa meskipun geolocation tidak didukung
    }
  }, []);

  useEffect(() => {
    const fetchAttendanceStatus = async () => {
      try {
        const status = await getAttendanceStatus(user.id);
        if (status.length > 0) {
          setAttendanceStatus({
            checkInTime:
              status.find((s) => s.type === "check-in")?.time || null,
            checkOutTime:
              status.find((s) => s.type === "check-out")?.time || null,
            isLate: status.find((s) => s.type === "check-in")?.isLate || false,
          });
        }
      } catch (error) {
        window.logger.error("Error saat mengambil status kehadiran:", error);
      }
    };

    if (user) {
      fetchAttendanceStatus();
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: 2,
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "500px",
          marginBottom: 2,
        }}
      >
        <UserProfileHeader userProfile={userProfile} />
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <Grid
        container
        spacing={1}
        sx={{
          marginBottom: 2,
          width: "100%",
          maxWidth: "500px",
          overflowX: "auto",
          display: "flex",
          flexWrap: "nowrap",
        }}
        ref={(grid) => {
          // Fokuskan item hari ini setelah komponen dimuat
          if (grid) {
            const todayIndex = weekDates.findIndex(
              (date) =>
                date.day === new Date().getDate().toString().padStart(2, "0")
            );
            if (todayIndex !== -1) {
              grid.children[todayIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        }}
      >
        {weekDates.map((date, index) => (
          <Grid
            item
            key={index}
            xs
            sx={{
              minWidth: "25%", // Menampilkan 4 item dengan lebar minimal 25%
              flex: "0 0 auto", // Mencegah item mengisi ruang lebih dari yang diperlukan
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                padding: 1,
                textAlign: "center",
                borderRadius: 1,
                backgroundColor:
                  index === new Date().getDay() ? "primary.main" : "grey.50",
                color: index === new Date().getDay() ? "white" : "text.primary",
              }}
            >
              <Typography variant="subtitle2">{date.day}</Typography>
              <Typography variant="caption">{date.dayName}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Card
            sx={{
              height: "100%",
              backgroundColor: "#EFF6EF",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {" "}
              {isWithinRadius && currentAllowedLocation ? (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Lokasi saat ini di area:
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontWeight: 600 }}
                  >
                    {currentAllowedLocation.address}
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" color="red">
                  Anda berada diluar radius pekerjaan
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: "100%", backgroundColor: "#EFF6EF" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h2">{currentTime}</Typography>
              <Typography variant="subtitle2">{currentDate}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%", backgroundColor: "#EFF6EF" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                Penting!!
              </Typography>
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Grid item xs={10}>
                  <Typography variant="body2">
                    Jika kamu sudah di lokasi kerja, namun lokasi berwarna
                    merah, mohon untuk klik tombol ini
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{
                      borderRadius: "50%",
                      border: "1px solid",
                      color: "primary.main",
                    }}
                    onClick={() => window.location.reload()}
                  >
                    <MdRefresh />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="subtitle2" gutterBottom>
        Aktivitas Anda{" "}
      </Typography>

      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Card>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: 2 }}>
                {attendanceStatus.checkInTime && (
                  <FaCheck color="green" size={20} />
                )}{" "}
                {/* Tampilkan ceklis jika sudah check in */}
              </Box>
              <Box>
                <Typography variant="body1" fontWeight={600}>
                  Masuk
                </Typography>
                <Typography variant="body2">
                  {attendanceStatus.checkInTime || "Belum Absen"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: 2 }}>
                {attendanceStatus.checkOutTime && (
                  <FaCheck color="green" size={20} />
                )}{" "}
                {/* Tampilkan ceklis jika sudah check out */}
              </Box>
              <Box>
                <Typography variant="body1" fontWeight={600}>
                  Pulang
                </Typography>
                <Typography variant="body2">
                  {attendanceStatus.checkOutTime || "Belum Absen"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() =>
            handleNavigateToPresensi(
              attendanceStatus.checkInTime ? "check-out" : "check-in"
            )
          } // Panggil fungsi navigate saat tombol diklik
          disabled={
            attendanceStatus.checkInTime && attendanceStatus.checkOutTime
          } // Tambahkan logika untuk menonaktifkan tombol
        >
          {attendanceStatus.checkInTime
            ? "Klik untuk Absen Pulang"
            : "Klik untuk Absen Masuk"}
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;
